import React from "react"
import mobileimage from "../../assets/images/banners/service-images/mobile.jpg"

const MobileApplication = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img mobile-application"
          data-bg="assets/images/banners/service-banners/mobile.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">
                    Mobile Application Solutions
                  </h1>
                  <h5 className="font-weight--normal text-white">
                    In today's world, when consumers are a company's most
                    precious asset and must be prioritized above all.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Mobile <span className="text-color-primary">Application Development</span></h2>
                  We're pushing the boundaries of what's possible on
                  mobile by developing revolutionary experiences for
                  clients ranging from huge corporations to innovative
                  companies and creative entrepreneurs. We ensure that
                  your concept is translated into a beautifully
                  designed, user-friendly, and engaging mobile solution
                  of exceptional quality using our extensive
                  understanding of native iOS, Android, and
                  cross-platform app development.
                  <div className="mt-30 title-wrap">
                    <ol className="retail-list" style={{ columns: "1" }}>
                      <li>Native iOS mobile app development</li>
                      <li>Native Android mobile app development</li>
                      <li>Cross-Platform mobile solutions</li>
                      <li>Responsive HTML5 mobile applications</li>
                      <li>Hybrid app development</li>
                      <li>Assurance, and Test Automation</li>
                      <li>Research, Discovery and Strategy services</li>
                      <li>Legacy application modernization</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={mobileimage} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Mobile Application <span className="text-color-primary">Development Expertise</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Conferencing and video/audio calling applications
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> IoT & wearables </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Games </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> mCommerce </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> VR and AR applications </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Mobile analytics application </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Finance apps </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Social Media and Marketing apps</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Mobile CMS</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">BYOD implementation</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> mHealth and Telemedicine </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}
export default MobileApplication
