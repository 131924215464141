import * as React from "react"
import Layout from "../components/layout"
import MobileApplication from "../components/Services/MobileApplication"






const mobileapplication = () => {
  return (
  <Layout>
   <MobileApplication/>
  </Layout>
)
};
export default mobileapplication;
